<template lang="pug">
.call-for-register.mini(v-if="mini")
  span.black Начните работу в системе ТендерМонитор прямо сейчас
  .actions
    ui-button.landing-button(v-if="!isLanding" type="secondary" @click="goToMain") Узнать больше
    ui-button.register-button(@click="showDialog('registration')") Получить полный доступ на 7 дней бесплатно

.call-for-register(v-else)
  span.main Еще больше закупок для Вас в системе ТендерМонитор
  span.secondary Все тендеры и госзакупки России {{ getYear() }}
  span.secondary Сервис поиска тендеров по № 44-ФЗ, № 223-ФЗ, ПП-615
  ui-button.register-button(@click="showDialog('registration')") Получить полный доступ на 7 дней бесплатно
  span.link(@click="goToMain") Перейти на главную
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useLandingLogin } from "@/use/auth/useLandingLogin";
import { getYear } from "@/utils/formatter/dateFormatter";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";

import UiButton from "@/components/ui/button/UiButton.vue";
import { Tabs } from "~/router/tabs";

export default defineComponent({
  name: "CallForRegistration",
  methods: {
    getYear,
  },
  components: {
    UiButton,
  },
  props: {
    mini: {
      type: Boolean,
      default: false,
    },
  },
  setup() {

    const route = useRoute()
    const router = useRouter()
    const { showDialog } = useLandingLogin();

    const isLanding = computed(() => route.name === Tabs.Landing)

    function goToMain() {
      router.push({ name: Tabs.Landing })
    }

    return {
      isLanding,
      showDialog,
      goToMain,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/landing/button';

.call-for-register {
  max-width: 1140px;
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 18px;
  padding: 40px 32px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;

  &.mini {
    padding: 32px 32px;
  }

  .main {
    color: #409eff;
    font-size: 28px;
    font-weight: bold;
  }

  .secondary {
    color: #324059;
    font-size: 18px;
    font-weight: 300;
  }

  .link {
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    color: var(--default-blue-color);
  }
}

.black {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: var(--main-text-color);
}

.actions {
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  justify-content: center;
}

@media(max-width: 640px) {
  .call-for-register {
    padding: 16px;

    &.mini {
      padding: 16px;
    }

    .main, .black {
      font-size: 20px;
    }
    .secondary, .link {
      font-size: 14px;
    }
  }
}
</style>

<template lang="pug">
#system-preferences.landing-page__block(:class="fromCard && 'border-box'")
  .landing-page__system-preferences
    .system-preferences__title Возможности ТендерМонитор
    ._register Изучите сами - 7 дней бесплатного тестирования!
    .landing-page__divider
      span._separator
    .system-preferences__subtitle
      | Управление всеми тендерами в едином месте
      br
      | Для индивидуальной работы и крупных тендерных отделов компании
    .system-preferences__grid
      .system-preferences__block(v-for="item of preferences" :key="item.label")
        .system-preferences__block._inner
          .system-preferences__icon
            ui-icon(:icon="item.icon" :size="22")
          span {{ item.label }}
        .system-preferences__subtitle {{ item.description }}
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "SystemCapabilities",
  components: {
    UiIcon,
  },
  props: {
    fromCard: {
      type: Boolean,
      default: false,
    },
  },
  setup() {

    const preferences = [
      {
        icon: UiIconNames.Landing_NewOrders, label: 'Новые заказы',
        description: 'Широкие возможности поиска закупок – от простых и быстрых запросов, до экспертного подбора скрытых тендеров. Ручной, автоматический, профессиональный поиск и расширенная аналитика в одной системе.',
      },
      {
        icon: UiIconNames.Landing_DataAnalysis, label: 'Подробная аналитика',
        description: 'Удобная и прозрачная аналитика покажет все нюансы рынка, конкурентов и заказчиков. Проводите полный анализ для принятия решения участия в государственных и коммерческих торгах.',
      },
      {
        icon: UiIconNames.Icon_Notification, label: 'Уверенность в работе',
        description: 'Автоматические уведомления о важных событиях. Email рассылки о новых закупках, напоминания о сроках подачи заявок, напоминания о торгах, напоминания о подписании контрактов.',
      },
      {
        icon: UiIconNames.Landing_Suitcase, label: 'Доступ к информации',
        description: 'Все данные о новых тендерах доступны круглосуточно из любого места, с любого устройства. Работайте эффективно.',
      },
      {
        icon: UiIconNames.Landing_Chat, label: 'Широкие возможности',
        description: 'Просмотр документации, расширенные фильтры, профессиональные шаблоны, система комментирования.',
      },
      {
        icon: UiIconNames.Landing_Monitor, label: 'Современный интерфейс',
        description: 'Понятный и удобный интерфейс. Приступайте к работе сразу. Помощь на всех этапах знакомства с сервисом.',
      },
    ]

    return {
      preferences,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/landing/landing";

.border-box {
  padding: 0;
}

.landing-page__system-preferences {
  @include white-box;

  display: flex;
  flex-flow: column;
  gap: 20px;

  padding: 90px 60px;

  ._register {
    text-align: center;
    align-self: center;
    color: #409eff;
    font-size: 20px;
    font-weight: 600;
  }

  .system-preferences__title {
    font-size: 32px;
    color: var(--main-text-color);
    font-weight: 600;
    text-align: center;
  }

  .system-preferences__subtitle {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    color: var(--secondary-text-color);
  }

  .system-preferences__grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    grid-column-gap: 44px;
    grid-row-gap: 60px;
    margin-top: 24px;
  }

  .system-preferences__block {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 8px;

    span {
      font-size: 16px;
      font-weight: 600;
      color: #324059;
    }
  }

  .system-preferences__icon {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eef5ff;
    color: #0681FC;
  }
}

@media (max-width: 1000px) {
  .landing-page__system-preferences {
    padding: 64px 44px;
    .system-preferences__title {
      font-size: 24px;
    }
  }
}

@media (max-width: 860px) {
  .landing-page__system-preferences {
    padding: 44px;
    .system-preferences__grid {
      grid-template-columns: repeat(2, minmax(100px, 1fr));
      grid-row-gap: 44px;
    }
  }
}

@media (max-width: 640px) {
  .landing-page__system-preferences {
    padding: 16px 32px;
    .system-preferences__title {
      font-size: 20px;
    }
    ._register {
      font-size: 16px;
    }
    .system-preferences__grid {
      grid-template-columns: repeat(1, minmax(100px, 1fr));
      grid-row-gap: 24px;
    }
    .system-preferences__block {
      &._inner {
        flex-flow: row wrap;
        justify-content: center;
        text-align: center;
      }
    }
  }
}

@media (max-width: 520px) {
  .landing-page__system-preferences {
    .system-preferences__subtitle {
      font-size: 13px;
      line-height: 18px;
    }
    .system-preferences__block {
      span {
        font-size: 14px;
      }
    }
    .system-preferences__icon {
      width: 36px;
      height: 36px;
      border-radius: 50%;

      :deep(.ui-icon) {
        height: 16px;
        width: 16px;
      }
    }
  }
}
</style>
